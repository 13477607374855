import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
} from '@material-ui/core';

import classes from './index.module.css';
import { customerSubmission } from '../../constants/endpoint';

function FormSection(props) {
  const { 
    open,
  } = props;
  // Fields
  const [firstName, changeFirstName] = useState('');
  const [lastName, changeLastName] = useState('');
  const [emailAddress, changeEmailAddress] = useState('');
  const [phoneNumber, changePhoneNumber] = useState('');
  const [companyTitle, changeCompanyTitle] = useState('');
  const [hiringNeed, changeHiringNeed] = useState('');
  // Validation
  const [firstNameVal, validateFirstName] = useState(false);
  const [lastNameVal, validateLastName] = useState(false);
  const [emailAddressVal, validateEmailAddress] = useState(false);
  const [phoneNumberVal, validatePhoneNumber] = useState(false);
  const [companyTitleVal, validateCompanyTitle] = useState(false);
  const [hiringNeedVal, validateHiringNeed] = useState(false);

  const fields = [
    {
      name: 'First Name',
      value: firstName,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateFirstName(true),
        },
      ],
    },
    {
      name: 'Last Name',
      value: lastName,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateLastName(true),
        },
      ],
    },
    {
      name: 'Email Address',
      value: emailAddress,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateEmailAddress(true),
        },
      ],
    },
    {
      name: 'Phone Number',
      value: phoneNumber,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validatePhoneNumber(true),
        },
      ],
    },
    {
      name: 'Company Title',
      value: companyTitle,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateCompanyTitle(true),
        },
      ],
    },
    {
      name: 'Hiring Need',
      value: hiringNeed,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateHiringNeed(true),
        },
      ],
    },
  ];


  const clearForm = () => {
    changeFirstName('');
    changeLastName('');
    changeEmailAddress('');
    changePhoneNumber('');
    changeCompanyTitle('');
    changeHiringNeed('');
  }

  const handleCustomerSubmit = () => {
    let validation = true;
    fields.forEach((field) => {
      // console.log(`field: ${field.name}`);
      // console.log(`value: ${field.value}`);
      field.rules.forEach((rule) => {
        const valid = rule.validate(field.value);
        // console.log(`rule: ${valid}`)
        if (valid) {
          rule.error();
          validation = false;
        }
      })
    });

    if (validation) {
      console.log('submitted');
      // console.log(open);

      const request = {
        method: 'POST',
        data: {
          firstName,
          lastName,
          email: emailAddress,
          phone: phoneNumber,
          companyName: companyTitle,
          hiringNeed,
        }
      }
      customerSubmission(request);

      clearForm();
      open(true);
    }
  }

  const handleFieldChange = (value, change, validation, changeValidation) => {
    if (validation) changeValidation(false);
    change(value);
  }
 
  return (
    <Grid
      className={classes.formContainer}
      container
    >
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          lg={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          lg={4}
        >
          <TextField
            fullWidth
            value={firstName}
            onChange={(e) => handleFieldChange(e.target.value, changeFirstName, firstNameVal, validateFirstName)}
            variant='outlined'
            label='First Name*'
            error={firstNameVal}
            helperText={firstNameVal ? 'First name is required' : ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#E9F5FE',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000',
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px',
              }
            }}
            style={{
              backgroundColor: '#fff',
            }}
          />
        </Grid>
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          lg={4}
        >
          <TextField
            fullWidth
            value={lastName}
            onChange={(e) => handleFieldChange(e.target.value, changeLastName, lastNameVal, validateLastName)}
            variant='outlined'
            label='Last Name*'
            error={lastNameVal}
            helperText={lastNameVal ? 'Last name is required': ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#E9F5FE',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px'
              }
            }}
            style={{
              backgroundColor: '#fff',
            }}
          />
        </Grid>
        <Grid
          item
          xs={0}
          lg={2}
        />
      </Grid>
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          lg={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          lg={4}
        >
          <TextField
            fullWidth
            value={emailAddress}
            onChange={(e) => handleFieldChange(e.target.value, changeEmailAddress, emailAddressVal, validateEmailAddress)}
            variant='outlined'
            label='Email Adress*'
            error={emailAddressVal}
            helperText={emailAddressVal ? 'Email address is required' : ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#E9F5FE',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px'
              }
            }}
            style={{
              backgroundColor: '#fff',
            }}
          />
        </Grid>
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          lg={4}
        >
          <TextField
            fullWidth
            value={phoneNumber}
            onChange={(e) => handleFieldChange(e.target.value, changePhoneNumber, phoneNumberVal, validatePhoneNumber)}
            variant='outlined'
            label='Phone Number*'
            error={phoneNumberVal}
            helperText={phoneNumberVal ? 'Phone number is required' : ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#E9F5FE',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px'
              }
            }}
            style={{
              backgroundColor: '#fff',
            }}
          />
        </Grid>
        <Grid
          item
          xs={0}
          lg={2}
        />
      </Grid>
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          lg={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          lg={4}
        >
          <TextField
            fullWidth
            value={companyTitle}
            onChange={(e) => handleFieldChange(e.target.value, changeCompanyTitle, companyTitleVal, validateCompanyTitle)}
            variant='outlined'
            label='Company Title*'
            error={companyTitleVal}
            helperText={companyTitleVal ? 'Company Title is required' : ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#E9F5FE',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px'
              }
            }}
            style={{
              backgroundColor: '#fff',
            }}
          />
        </Grid>
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          lg={4}
        >
          <FormControl
            hiddenLabel
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel
              id="hiring-need-label"
              shrink
              style={{
                color: '#000',
              }}
            >
              Hiring Need Over The Next Year*
            </InputLabel>
            <Select
              fullWidth
              id="hiringNeed"
              value={hiringNeed}
              onChange={(e) => handleFieldChange(e.target.value, changeHiringNeed, hiringNeedVal, validateHiringNeed)}
              labelId="hiring-need-label"
              label="Hiring Need Over The Next Year*"
              notched={true}
              error={hiringNeedVal}
              style={{
                backgroundColor: '#fff',
                borderRadius: '0px'
              }}
            >
              <MenuItem disabled value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1-10">1-10</MenuItem>
              <MenuItem value="10-20">10-20</MenuItem>
              <MenuItem value="20+">20+</MenuItem>
            </Select>
            <FormHelperText
              error={hiringNeedVal}
              style={{
                display: hiringNeedVal ? 'block' : 'none'
              }}
            >
              Please select a Hiring Need option
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={0}
          lg={2}
        />
      </Grid>
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={2}
          lg={6}
        />
        <Grid
          className={classes.formButtonContainer}
          item
          xs={8}
          lg={4}
        >
          <Button
            className={clsx(classes.outlinedSkyBlueFilledButton, classes.formButton)}
            onClick={() => handleCustomerSubmit()}
          >
            Submit
          </Button>
        </Grid>
        <Grid
          item
          xs={2}
        />
      </Grid>
    </Grid>
  )
}

FormSection.propTypes = {}

export default FormSection
