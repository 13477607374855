import axios from 'axios';

const defaultParameters = (content) => {
  const method = content ? content.method : 'get';
  const headers = content ? content.headers : {};
  const params = content ? content.params : {};
  const data = content ? content.data : {};
  return ({
    method,
    headers,
    params,
    data,
  });
};

const api = async (url, content) => {
  const {
    method,
    headers,
    params,
    data,
  } = defaultParameters(content);
  const result = await axios({
    method,
    url,
    headers,
    params,
    data,
  })
    .then((res) => res.data)
    .catch((error) => error.response.data);
  return result;
};

export default api;
