import React from 'react'
import PropTypes from 'prop-types'

import data from './constants/content';
import NavMenu from './components/NavMenu';
import SubmissionContent from './components/SubmissionContent';
import Footer from './components/Footer';

function LandingRoot(props) {
  return (
    <>
      <NavMenu
        {...data}
      />
      <SubmissionContent
        {...data}
      />
      <Footer
        {...data}
      />
    </>
  )
}

LandingRoot.propTypes = {}

export default LandingRoot
