import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import classes from './index.module.css';

function TornadoSection(props) {
  const {
    writingSample,
    receptiviti,
    id,
  } = props;

  const negativeTextArray = [
    {
      text: 'Cold',
      replace: 'Indifferent',
    },
    {
      text: 'Insecure',
      replace: 'Uncertain',
    },
    {
      text: 'Aggressive',
      replace: 'Competitive',
    },
    {
      text: 'Anxious',
      replace: 'Apprehensive',
    },
    {
      text: 'Stressed',
      replace: 'Concerned',
    },
  ];

  const replaceNegative = (value) => {
    const foundValue = negativeTextArray.find((neg) => {
      return value === neg.text;
    })
    return foundValue ? foundValue.replace : value;
  }

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const renderData = () => {
    let entries = Object.entries(
      receptiviti.receptiviti_scores.percentiles
    );

    let sortedEntries = entries.sort((a, b) => {
      return b[1] - a[1];
    });

    let filteredEntries = sortedEntries.filter((entry) => {
      if (
        entry[0] !== "sexual_focus" &&
        entry[0] !== "conscientiousness" &&
        entry[0] !== "trusting" &&
        entry[0] !== "dutiful" &&
        entry[0] !== "food_focus" &&
        entry[0] !== "religion_oriented" &&
        entry[0] !== "body_focus" &&
        entry[0] !== "depression" &&
        entry[0] !== "friend_focus"
      )
        return entry;
    });

    let greaterThan50 = filteredEntries.filter((entry) => entry[1] >= 50);
    let lessThan50 = filteredEntries.filter((entry) => entry[1] < 50);

    let tornado = [];
    let bottomTornado = [];
    const tornadoColor = '#395169';
    const tornadoTextColor = '#5F5F5F';
    const tornadoPercentColor = '#fff'
    const tornadoWidthFormula = (value) => 55 * (value / 100);
    for (var i = 0; i <= greaterThan50.length; i = i + 2) {
      try {
        tornado.push(
          <Box
            className={classes.dataWrapper}
          >
            <Box
              className={classes.dataContainerLeft}
            >
              <Typography
                style={{
                  color: tornadoTextColor,
                }}
                className={classes.dataContainerLeftTitle}
              >
                { replaceNegative(toTitleCase(greaterThan50[i][0].replace("_", " "))) }
              </Typography>
              <Box
                style={{
                  backgroundColor: tornadoColor,
                  width: `${tornadoWidthFormula(greaterThan50[i][1])}%`,
                }}
                className={classes.dataContainerLeftBarContainer}
              >
                <Typography
                  style={{
                    color: tornadoPercentColor,
                  }}
                  className={classes.dataContainerLeftBarPercent}
                >
                  {`${parseInt(greaterThan50[i][1])}%`}
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.dataContainerRight}
            >
              <Box
                style={{
                  backgroundColor: tornadoColor,
                  width: `${tornadoWidthFormula(greaterThan50[i + 1][1])}%`,
                }}
                className={classes.dataContainerRightBarContainer}
              >
                <Typography
                  style={{
                    color: tornadoPercentColor,
                  }}
                  className={classes.dataContainerRightBarPercent}
                >
                  {`${parseInt(greaterThan50[i + 1][1])}%`}
                </Typography>
              </Box>
              <Typography
                style={{
                  color: tornadoTextColor,
                }}
                className={classes.dataContainerRightTitle}
              >
                { replaceNegative(toTitleCase(greaterThan50[i + 1][0].replace("_", " "))) }
              </Typography>
            </Box>
          </Box>
        )
      } catch (e) {}
    }

    const midline = (
      <Box
        className={classes.midlineContainer}
      >
        <Typography
          className={classes.subtitle}
        >
          50th Percentile
        </Typography>
        <div
          className={classes.midlineSpacer}
        />
      </Box>
    );

    for (var i = 0; i <= lessThan50.length; i = i + 2) {
      try {
        bottomTornado.push(
          <Box
            className={classes.dataWrapper}
          >
            <Box
              className={classes.dataContainerLeft}
            >
              <Typography
                style={{
                  color: tornadoTextColor,
                }}
                className={classes.dataContainerLeftTitle}
              >
                { replaceNegative(toTitleCase(lessThan50[i][0].replace("_", " "))) }
              </Typography>
              <Box
                style={{
                  backgroundColor: tornadoColor,
                  width: `${tornadoWidthFormula(lessThan50[i][1])}%`,
                }}
                className={classes.dataContainerLeftBarContainer}
              >
                <Typography
                  style={{
                    color: tornadoPercentColor,
                  }}
                  className={classes.dataContainerLeftBarPercent}
                >
                  {`${parseInt(lessThan50[i][1])}%`}
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.dataContainerRight}
            >
              <Box
                style={{
                  backgroundColor: tornadoColor,
                  width: `${tornadoWidthFormula(lessThan50[i + 1][1])}%`,
                }}
                className={classes.dataContainerRightBarContainer}
              >
                <Typography
                  style={{
                    color: tornadoPercentColor,
                  }}
                  className={classes.dataContainerRightBarPercent}
                >
                  {`${parseInt(lessThan50[i + 1][1])}%`}
                </Typography>
              </Box>
              <Typography
                style={{
                  color: tornadoTextColor,
                }}
                className={classes.dataContainerRightTitle}
              >
                { replaceNegative(toTitleCase(lessThan50[i + 1][0].replace("_", " "))) }
              </Typography>
            </Box>
          </Box>
        );
      } catch (e) {}
    }
    return [...tornado, midline, ...bottomTornado];
  };

  return (
    <Box
      className={classes.section}
    >
      <Typography
        className={classes.subtitle}
      >
        All Scores
      </Typography>
      { receptiviti ? renderData() : null }
    </Box>
  )
}

TornadoSection.propTypes = {}

export default TornadoSection
