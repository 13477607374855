import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';


import history from '../history';
import { candidateReportData, candidateReportWriting } from './constants/endpoint';
import classes from './index.module.css';
import TitleSection from './components/TitleSection';
import PersonalitySection from './components/PersonalitySection';
import RecommendationSection from './components/RecommendationSection';
import ThinkingSection from './components/ThinkingSection';
import ScoreSection from './components/ScoreSection';
import TornadoSection from './components/TornadoSection';

function LandingCommsReport(props) {
  const [currentUser, changeCurrentUser] = useState({});
  const [userWritingSample, changeUserWritingSample] = useState({});
  const [loadingPage, changeLoadingPage] = useState(false);
  const userid = props.match.params.userid;

  const redirectToHome = () => {
    window.location.href = "/";
  }

  const generatePDF = async () => {
    const currentReport = document.getElementById("reportContainer");
    const doc = new jsPDF('landscape');

    // Render HTML to canvas
    const canvas = await html2canvas(currentReport);

    // Convert canvas to an image
    const imgData = canvas.toDataURL('image/png');

    const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width
    const pageHeight = doc.internal.pageSize.getHeight(); // Get the page height
    const imageWidth = pageWidth; // Set the image width to match the page width
    const imageHeight = (canvas.height * imageWidth) / canvas.width; // Calculate the proportional height

    // Add image to the PDF
    doc.addImage(imgData, 'PNG', 0, 0, imageWidth, imageHeight);
    // doc.addImage(imgData, 'PNG', 10, 10, 190, 0);

    // Save the PDF
    doc.save('InputCandidate_CommunicationStyle.pdf');
  }

  useEffect(() => {
    if (!userid) return;

    const handleDataLoad = async (value) => {
      const userData = await candidateReportData(value)
      if (userData.error) return redirectToHome();
      const sampleId = userData.candidateWritingSampleId;
      const sampleData = await candidateReportWriting(sampleId);
      if (sampleData.error) return redirectToHome();
      
      changeCurrentUser(userData);
      changeUserWritingSample(sampleData);
      if (userData && sampleData) {
        setTimeout(() => changeLoadingPage(true), 1000);
      }
    }

    handleDataLoad(userid);
  }, [userid]);

  const loadingState = () => (
    <Box
      className={classes.loadingContainer}
    >
      <LoopIcon
        className={clsx(classes.loadingIcon, classes.loadingSpinAnimation)}
      />
      <Box
        className={classes.loadingTextContainer}
      >
        <Typography
          className={classes.loadingText}
        >
          Loading 
        </Typography>
        <Box className={classes.dotPulse} />
      </Box>
    </Box>
  )

  return loadingPage ? (
    <Box
      style={{
        position: 'relative',
      }}
    >
      <Box
        id='reportContainer'
        className={classes.reportContainer}
      >
        <Grid
          container
        >
          <Grid
            item
            xs={2}
          />
          <Grid
            item
            xs={8}
          >
            <TitleSection
              userid={userid}
              {...currentUser}
            />
          </Grid>
          <Grid
            item
            xs={2}
          />
        </Grid>
        <Grid
          container
          className={classes.reportDataContainer}
        >
          <Grid
            item
            xs={0}
            md={1}
            xl={2}
          />
          <Grid
            className={classes.column}
            item
            xs={12}
            md={5}
            xl={4}
          >
            <PersonalitySection
              {...userWritingSample}
            />
            <RecommendationSection
              {...userWritingSample}
            />
            <ThinkingSection
              {...userWritingSample}
            />
          </Grid>
          <Grid
            className={classes.column}
            item
            xs={12}
            md={5}
            xl={4}
          >
            <ScoreSection
              {...userWritingSample}
            />
            <TornadoSection
              {...userWritingSample}
            />
          </Grid>
          <Grid
            item
            xs={0}
            md={1}
            xl={2}
          />
        </Grid>
        <Box
          className={classes.bottomContainer}
        >
          <img
            className={classes.bottomLogo}
            src="/icons/poweredby-blue-icon.svg"
            alt=""
          />
        </Box>
      </Box>
      <Box
        className={classes.buttonContainer}
      >
        <Button
          className={classes.outlinedSkyBlueFilledButton}
          onClick={() => generatePDF()}
        >
          Download PDF
        </Button>
      </Box>
    </Box>
  ) : loadingState();
}

LandingCommsReport.propTypes = {}

export default LandingCommsReport
