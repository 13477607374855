import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Modal, Typography, Button } from '@material-ui/core';

import classes from './index.module.css';

function SubmissionModal(props) {
  const {
    title,
    description,
    button,
    open,
    close,
  } = props
  return (
    <Modal
      open={open}
      onClose={close}
    >
      <div
        className={classes.modalContainer}
      >
        <Typography
          className={classes.modalTitle}
          variant='h5'
        >
          { title }
        </Typography>
        <Typography
          className={classes.modalDescription}
        >
          { description }
        </Typography>
        <Button
          className={clsx(classes.outlinedSkyBlueFilledButton, classes.modalButton)}
          onClick={() => close()}
        >
          { button }
        </Button>
      </div>
    </Modal>
  )
}

SubmissionModal.propTypes = {}

export default SubmissionModal
