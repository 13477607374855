import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LoginIcon from '@material-ui/icons/Input';

import classes from './index.module.css';

function NavMenuTemplate(props) {
  const { signInLink, desktopLogo, mobileLogo } = props;
  const pathname = window.location.pathname.replace('/', '');

  return (
    <nav
      className={classes.navContainer}
    >
      <Grid
        className={classes.navFullHeight}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.navFullHeight}
          item
          xs={6}
          md={3}
        >
          <Box
            className={clsx(classes.navLinkLogo, classes.logoDesktop)}
          >
            <Link
              to="/"
            >
              <img
                className={clsx(classes.logo, classes.desktopPadding)}
                src={desktopLogo}
                alt=""
              />
            </Link>
          </Box>
          <Box
            className={clsx(classes.navLinkLogo, classes.logoMobile)}
          >
            <Link
              to="/"
            >
              <img
                className={classes.logo}
                src={mobileLogo}
                alt=""
              />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={1}
          md={2}
        />
        <Grid
          item
          xs={5}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <a
            className={classes.iconStyle}
            href="https://www.facebook.com/inputtechnologysolutions/"
            target='_blank'
            rel='noreferrer'
          >
            <img
              src="/icons/fb-icon.svg"
              alt=""
            />
          </a>
          <a
            className={classes.iconStyle}
            href="https://www.linkedin.com/company/inputtechnologysolutions/"
            target='_blank'
            rel='noreferrer'
          >
            <img
              src="/icons/ln-icon.svg"
              alt=""
            />
          </a>
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
    </nav>
  );
}

NavMenuTemplate.propTypes = {};

export default NavMenuTemplate;
