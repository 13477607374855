import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';

import classes from './index.module.css';

function InfoSection(props) {
  return (
    <Box
      className={classes.infoSection}
    >
      <Grid
        className={classes.rowContainer}
        container
      >
        <Grid
          item
          xs={2}
        />
        <Grid
          item
          xs={8}
          lg={4}
        >
          <div
            className={classes.infoContainer}
          >
            <img
              className={classes.infoIcon}
              src="/icons/message-icon.svg"
              alt=""
            />
            <Typography
              className={classes.infoDescription}
            >
              A proprietary AI technology, powered by GoGig, utilizes Natural Language Processing extrapolating 70 data points on a candidate’s communication style, without bias, in under one second.
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={8}
          lg={4}
        >
          <div
            className={classes.infoContainer}
          >
            <img
              className={classes.infoIcon}
              src="/icons/case-icon.svg"
              alt=""
            />
            <Typography
              className={classes.infoDescription}
            >
              Communication Style Reports and Recommendations have helped hiring professionals save more than 50% of their time on due diligence for candidate screenings.
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={2}
        />
      </Grid>
      <Grid
        className={classes.rowContainer}
        container
      >
        <Grid
          item
          xs={2}
        />
        <Grid
          item
          xs={8}
          lg={4}
        >
          <div
            className={classes.infoContainer}
          >
            <img
              className={classes.infoIcon}
              src="/icons/message-icon.svg"
              alt=""
            />
            <Typography
              className={classes.infoDescription}
            >
              A proprietary AI technology, powered by GoGig, utilizes Natural Language Processing extrapolating 70 data points on a candidate’s communication style, without bias, in under one second.
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={8}
          lg={4}
        >
          <div
            className={classes.infoContainer}
          >
            <img
              className={classes.infoIcon}
              src="/icons/case-icon.svg"
              alt=""
            />
            <Typography
              className={classes.infoDescription}
            >
              Communication Style Reports and Recommendations have helped hiring professionals save more than 50% of their time on due diligence for candidate screenings.
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={2}
        />
      </Grid>
    </Box>
  )
}

InfoSection.propTypes = {}

export default InfoSection
