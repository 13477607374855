import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx';
import { 
  Grid,
  Typography,
} from '@material-ui/core';

import classes from './index.module.css';

function HeroSection(props) {
  const {
    contentHeroImg,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroTitle3,
    contentHeroDescription,
  } = props;
  return (
    <>
      <Grid
        container
        className={classes.heroContainer}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '15%',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={3}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            lg={6}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              <div
                className={classes.desktop}
              >
                Gain Deeper Candidate Insight
              </div>
              <div
                className={classes.mobile}
              >
                Gain Deeper
                <br />
                Candidate Insight
              </div>
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              <div
                className={classes.desktop}
              >
                with Communication Style Reports
              </div>
              <div
                className={classes.mobile}
              >
                with Communication
                <br />
                Style Reports
              </div>
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              and Recommendations
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
              { contentHeroDescription }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.heroSubContainer}
      >
        <Grid
          item
          xs={1}
          sm={2}
          lg={3}
          className={classes.heroContainerItems}
        />
        <Grid
          item
          xs={10}
          sm={4}
          lg={3}
          className={clsx(classes.heroContainerItems, classes.heroContainerItemImage, classes.heroContainerItemPlacement)}
        >
          <img
            width="100%"
            src="/images/report.png"
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          lg={4}
          className={clsx(classes.heroContainerItems, classes.heroContainerItemDescription, classes.heroContainerItemPlacement)}
        >
          <Typography
            className={classes.heroSubDescription}
          >
            Based on 70 data points evaluated, the Communication Style Report and Recommendation provides valuable insight to create a more effective outreach with the candidate.
          </Typography>
          <br />
          <Typography
            className={clsx(classes.heroSubDescription, classes.heroSubDescriptionColor)}
            component="span"
          >
            Interested in more information?
          </Typography>
          <Typography
            className={classes.heroSubDescription}
            component="span"
          >
            Provide your details below to learn more:
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

HeroSection.propTypes = {}

export default HeroSection;
