import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';

import classes from './index.module.css';

function Footer(props) {
  return (
    <footer
      className={classes.footerContainer}
    >
      <Grid
        container
        className={classes.footerGrid}
      >
        <Grid
          item
          xs={0}
          md={1}
        />
        <Grid
          className={clsx(classes.footerSectionStart, classes.desktop)}
          item
          xs={12}
          md={2}
        >
          <Typography>
            Input Technology Solutions
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          md={2}
        >
          <Typography
            style={{
              marginRight: '10px',
            }}
          >
            Connect With Us
          </Typography>
          <a
            href='https://www.facebook.com/inputtechnologysolutions/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className={classes.footerIcon}
              src="/icons/facebook.svg"
              alt=""
            />
          </a>
          <a
            href='https://www.linkedin.com/company/inputtechnologysolutions/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className={classes.footerIcon}
              src="/icons/linkedin.svg"
              alt=""
            />
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
        />
        <Grid
          className={classes.footerSectionEnd}
          item
          xs={12}
          md={2}
        >
          <Typography
            className={classes.mobile}
            style={{
              marginBottom: '10px'
            }}
          >
            Input Technology Solutions
          </Typography>
          <img
            className={classes.footerGogigLogo}
            src="/icons/poweredby-icon.svg"
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={0}
          md={1}
        />
      </Grid>
    </footer>
  );
}

Footer.propTypes = {};

export default Footer;
