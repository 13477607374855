import api from '../utils/api';

const domain = 'https://workforce.inputtechnologysolutions.com'

export function candidateSubmission(params) {
  return api(`${domain}:4000/api/CandidateSubmissions`, params);
}

export function candidateWritingSample(params) {
  return api(`${domain}:4000/api/CandidateWritingSamples`, params);
}

export function candidateReportData(id, params) {
  return api(`${domain}:4000/api/CandidateSubmissions/${id}`, params)
}
