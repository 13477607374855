import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import classes from './index.module.css';

function PersonalitySection(props) {
  const {
    writingSample,
    receptiviti,
    id,
  } = props;

  const renderPersonalityDetails = () => {
    const result = receptiviti ? receptiviti.personality_snapshot.map((snapshot) => (
      <Box
        className={classes.detailsContainer}
      >
        <Typography
          className={classes.subtitle}
        >
          { snapshot.summary }
        </Typography>
        <Typography
          className={classes.description}
        >
          {snapshot.description[0].content || snapshot.description}
        </Typography>
      </Box>
    )) : null

    return result;
  }

  return (
    <Box
      className={classes.section}
    >
      <Box
        className={classes.container}
      >
        <img
          className={classes.icon}
          src="/icons/camera-icon.svg"
          alt=""
        />
        <Typography
          className={classes.title}
          component="span"
        >
          Communication Style Snapshot
        </Typography>
      </Box>
      { renderPersonalityDetails() }
    </Box>
  )
}

PersonalitySection.propTypes = {}

export default PersonalitySection
