import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import classes from './index.module.css';

function ScoreSection(props) {
  const {
    writingSample,
    receptiviti,
    id,
  } = props;

  const scores = receptiviti ? receptiviti.receptiviti_scores.percentiles : {};

  const negativeTextArray = [
    {
      text: 'Cold',
      replace: 'Indifferent',
    },
    {
      text: 'Insecure',
      replace: 'Uncertain',
    },
    {
      text: 'Aggresive',
      replace: 'Competitive',
    },
    {
      text: 'Anxious',
      replace: 'Apprehensive',
    },
    {
      text: 'Stressed',
      replace: 'Concerned',
    },
  ];

  const replaceNegative = (value) => {
    const foundValue = negativeTextArray.find((neg) => {
      return value === neg.text;
    })
    return foundValue ? foundValue.replace : value;
  }

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  function getTopFiveValues(obj) {
    const entries = Object.entries(obj);
    const sorted = entries.sort((a, b) => b[1] - a[1]);
    const topFive = sorted.slice(0, 5);
    const result = {};
    topFive.forEach(entry => {
      result[entry[0]] = entry[1];
    });
    return result;
  }

  const renderScoreBubble = () => {
    const topFive = Object.entries(getTopFiveValues(scores));
    const bubbleSizes = [65, 60, 58, 55, 50];
    return topFive ? topFive.map((score, i) => {
      return (
        <Box
          key={i}
          className={classes.bubbleContainer}
        >
          <Box
            className={classes.bubble}
            style={{
              height: `${bubbleSizes[i]}px`,
              width: `${bubbleSizes[i]}px`,
            }}
          >
            <Typography
              className={classes.bubbleText}
              component="span"
            >
              { `${parseInt(score[1])}%` }
            </Typography>
          </Box>
          <Typography
            className={classes.bubbleDescription}
          >
            { replaceNegative(toTitleCase(score[0]).replace("_", " ")) }
          </Typography>
        </Box>
      )
    }) : <div>empty</div>
  }

  return (
    <Box
      className={classes.section}
    >
      <Typography
        className={classes.title}
      >
        GoGig Natural Language Processing Scores
      </Typography>
      <Typography
        className={classes.subtitle}
      >
        Top 5
      </Typography>
      <Box
        className={classes.bubbleSection}
      >
        { renderScoreBubble() }
      </Box>
    </Box>
  )
}

ScoreSection.propTypes = {}

export default ScoreSection
