import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';

import classes from './index.module.css';

function InfoSection(props) {
  return (
    <Box
      className={classes.infoSection}
    >
      <Grid
        className={classes.rowContainer}
        container
      >
        <Grid
          item
          xs={1}
          md={2}
        />
        <Grid
          className={classes.gridInfoContainer}
          item
          xs={10}
          md={4}
        >
          <div
            className={classes.infoContainer}
          >
            <img
              className={classes.infoIcon}
              src="/icons/message-icon.svg"
              alt=""
            />
            <Typography
              className={classes.infoDescription}
            >
              A proprietary AI technology, powered by GoGig, utilizes Natural Language Processing extrapolating 70 data points on a candidate’s communication style, without bias, in under one second.
            </Typography>
          </div>
        </Grid>
        <Grid
          className={classes.gridDesktopHide}
          item
          xs={1}
        />
        <Grid
          className={classes.gridDesktopHide}
          item
          xs={1}
        />
        <Grid
          item
          xs={10}
          md={4}
        >
          <div
            className={classes.infoContainer}
          >
            <img
              className={classes.infoIcon}
              src="/icons/case-icon.svg"
              alt=""
            />
            <Typography
              className={classes.infoDescription}
            >
              Communication Style Reports and Recommendations have helped hiring professionals save more than 50% of their time on due diligence for candidate screenings.
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={1}
          md={2}
        />
      </Grid>
      <Grid
        className={classes.rowContainer}
        container
      >
        <Grid
          item
          xs={1}
          md={2}
        />
        <Grid
          className={classes.gridInfoContainer}
          item
          xs={10}
          md={4}
        >
          <div
            className={classes.infoContainer}
          >
            <img
              className={classes.infoIcon}
              src="/icons/hands-icon.svg"
              alt=""
            />
            <Typography
              className={classes.infoDescription}
            >
              With Communication Style Reports and Recommendations, hiring professionals can establish a human-to-human connection with candidates.
            </Typography>
          </div>
        </Grid>
        <Grid
          className={classes.gridDesktopHide}
          item
          xs={1}
        />
        <Grid
          className={classes.gridDesktopHide}
          item
          xs={1}
        />
        <Grid
          item
          xs={10}
          md={4}
        >
          <div
            className={classes.infoContainer}
          >
            <img
              className={classes.infoIcon}
              src="/icons/megaphone-icon.svg"
              alt=""
            />
            <Typography
              className={classes.infoDescription}
            >
              The Communication Style Reports and Recommendations component increases enterprise hiring and improves recruiting productivity overall.
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={1}
          md={2}
        />
      </Grid>
    </Box>
  )
}

InfoSection.propTypes = {}

export default InfoSection
