const data = {
  desktopLogo: '/images/logo.svg',
  mobileLogo: '/images/logo.svg',
  signInLink: 'https://mycarepath.org/getstarted',
  contentHeroImg: '/images/hero-background.png',
  contentHeroTitle1: 'Gain Deeper Candidate Insight',
  contentHeroTitle2: 'with Communication Style Reports',
  contentHeroTitle3: 'and Recommendations',
  contentHeroDescription: 'Input Technology Solutions uses AI and Natural Language Processing, powered by GoGig, to better understand candidate Communication Style through the analysis of a writing sample.',
  contentHeroButtonLink: 'https://mycarepath.org/getstarted',
  contentHeroButtonText: 'Get Started',
  contentHeroButtonSupport: 'Learn More',
};

export default data;
