import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import classes from './index.module.css';

function RootBodyTemplate(props) {
  const {
    contentHeroImg,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroTitle3,
    contentHeroDescription,
    contentHeroButtonText,
    contentHeroButtonLink,
    signInText,
    recentlyAdded,
    contentBody,
    contentBodyButtonText,
    contentBodyButtonLink,
  } = props;

  const scrollToBodyContent = () => {
    scroller.scrollTo('bodyContent', {
      duration: 800,
      delay: 0,
      offset: -90,
      smooth: 'easeInOutQuart',
    });
  };

  const bodyText = (
    <Grid
      className="bodyContent"
      container
      style={{
        margin: '2.5rem 0 ',
      }}
    >
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            '@media (max-width: 901px)': {
              padding: '0 20px',
            },
          }}
        >
          <Typography
            variant="h2"
            className={classes.bodyTitle}
          >
            Ways the MyCarePath Platform Can Benefit You
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            marginTop: '6.25rem',
            marginBottom: '3.125rem',
          }}
        >
          <Grid
            item
            xs={0}
            lg={2}
          />
          <Grid
            item
            container
            xs={12}
            lg={8}
          >
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/message-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem !important',
                    fontFamily: '"Lato" !important',
                    fontWeight: '400 !important',
                  }}
                >
                  Entertain conversations about potential career advancement without jeopardizing your current role
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/bag-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem !important',
                    fontFamily: '"Lato" !important',
                    fontWeight: '400 !important',
                  }}
                >
                  Have real conversations with hiring companies that express interest in you —no résumé required
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/collab-hands-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem !important',
                    fontFamily: '"Lato" !important',
                    fontWeight: '400 !important',
                  }}
                >
                  Anonymity in the platform will remove all implicit bias and discrimination in the hiring process
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/megaphone-red-icon.svg"
                  alt=""
                />
                <Typography
                  style={{
                    fontSize: '1rem !important',
                    fontFamily: '"Lato" !important',
                    fontWeight: '400 !important',
                  }}
                >
                  Stay informed and up to date with the latest information
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const landingContent = (
    <>
      <Grid
        container
        className={classes.heroContainer}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '15%',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={3}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            lg={6}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle1 }
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle2 }
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle3 }
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
              { contentHeroDescription }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
      >
        <Grid
          item
          xs={0}
          sm={1}
          lg={2}
          className={classes.heroContainerItems}
        />
        <Grid
          item
          xs={12}
          sm={10}
          lg={4}
          className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
        >
          <img
            width="100%"
            src="/images/report.png"
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          lg={4}
          className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
        >
          <img
            width="100%"
            src="/images/report.png"
            alt=""
          />
        </Grid>
      </Grid>
      { bodyText }
      <Grid
        container
        style={{
          padding: '2.5rem 0',
        }}
      >
        <Grid
          item
          xs={1}
          lg={2}
        />
        <Grid
          item
          xs={10}
          lg={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: '1.438rem !important',
              fontFamily: '"Lato" !important',
              fontWeight: '700 !important',
            }}
          >
            Are you interested in hiring talent from our community?
          </Typography>
          <Button
            className={classes.buttonSquareFilledRed}
            href={contentBodyButtonLink}
            style={{
              margin: '20px 0',
              width: '200px',
              height: '44px',
            }}
          >
            { contentBodyButtonText }
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      {
        landingContent
      }
    </Grid>
  );
}

RootBodyTemplate.propTypes = {}

export default RootBodyTemplate
