import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';

import classes from './index.module.css';

function TitleSection(props) {
  const {
    userid,
    firstName,
    lastName,
    email,
    phone,
  } = props;
  return (
    <Box
      className={classes.section}
    >
      <Typography
        className={classes.title}
      >
        Communication Style Report
      </Typography>
      <Typography
        className={clsx(classes.subtitle, classes.subtitleDesktop)}
      >
        {`${firstName || ''} ${lastName || ''} ${firstName || lastName ? ' | ' : ''} ${email || ''} ${email ? ' | ' : ''} ${phone || ''}`}
      </Typography>
      <Box
        className={classes.subtitleMobile}
      >
        <Typography
          className={classes.subtitle}
        >
          {`${firstName || ''} ${lastName || ''}`}
        </Typography>
        <Typography
          className={classes.subtitle}
        >
          { email || ''}
        </Typography>
        <Typography
          className={classes.subtitle}
        >
          { phone || ''}
        </Typography>
      </Box>
      <img
        className={classes.logo}
        src="/images/logo-color.svg"
        alt=""
      />
    </Box>
  )
}

TitleSection.propTypes = {}

export default TitleSection
