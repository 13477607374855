import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx';
import { 
  Grid,
  Typography,
} from '@material-ui/core';

import classes from './index.module.css';

function HeroSection(props) {
  const {
    contentHeroImg,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroTitle3,
    contentHeroDescription,
  } = props;
  return (
    <>
      <Grid
        container
        className={classes.heroContainer}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '15%',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={3}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            lg={6}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              Thank you for your recent submission
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
               As part of next steps, we are requesting a writing sample from you to share with hiring companies who are interested in your application. 
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

HeroSection.propTypes = {}

export default HeroSection;
