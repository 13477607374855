import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Slider } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import classes from './index.module.css';

function ThinkingSection(props) {
  const {
    writingSample,
    receptiviti,
    id,
  } = props;

  const thinkingStyleScore = receptiviti ? receptiviti.liwc_scores.analytic : 0;

  const boxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

  const InputSlider = withStyles({
    root: {
      color: '#4992ab',
      height: 8,
      padding: '15px 0',
    },
    thumb: {
      height: 28,
      width: 48,
      borderRadius: 15,
      backgroundColor: '#394658',
      boxShadow: boxShadow,
      marginTop: -11,
      marginLeft: -14,
      '&:focus, &:hover, &$active': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: boxShadow,
        },
      },
      '& .bar': {
        // display: inline-block !important;
        height: 9,
        width: 1,
        backgroundColor: '#27303c',
        marginLeft: 2,
        marginRight: 2,
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 12px)',
      top: -22,
      '& *': {
        background: 'transparent',
        color: '#000',
      },
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 25,
      opacity: 0.5,
      backgroundColor: '#c1d7d7',
    },
    mark: {
      display: 'none',
    },
    markActive: {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
    markLabel: {
      color: '#989898',
    },
    markLabelActive: {
      color: '#989898',
    },
  })(Slider);

  const inputMarks = [
    {
      value: 0,
      label: 'Emotional',
    },
    {
      value: 100,
      label: 'Analytical',
    },
  ];

  function InputThumbComponent(props) {
    return (
      <span {...props}>
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
      </span>
    );
  }

  return (
    <Box
      className={classes.section}
    >
      <Box
        className={classes.snapshotContainer}
      >
        <img
          className={classes.icon}
          src="/icons/brain-icon.svg"
          alt=""
        />
        <Typography
          className={classes.title}
          component="span"
        >
          Thinking Style
        </Typography>
      </Box>
      <Box
        className={classes.detailsContainer}
      >
        <InputSlider
          value={thinkingStyleScore}
          marks={inputMarks}
          ThumbComponent={InputThumbComponent}
        />
      </Box>
    </Box>
  )
}

ThinkingSection.propTypes = {}

export default ThinkingSection
