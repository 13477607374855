import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import classes from './index.module.css';
import HeroSection from '../HeroSection';
import FormSection from '../FormSection';
import InfoSection from '../InfoSection';
import SubmissionModal from '../SubmissionModal';

function SubmissionContent(props) {
  const [customerModal, handleCustomerModal] = useState(false);

  const submissionModalConfig = {
    title: 'Thank you for your submission',
    description: 'If a hiring company is interested in your application, you will hear back from a team member. ',
    button: 'DONE',
    open: customerModal,
    close: () => handleCustomerModal(false),
  }

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      <HeroSection
        {...props}
      />
      <FormSection
        open={handleCustomerModal}
        {...props}
      />
      <SubmissionModal
        {...submissionModalConfig}
      />
    </Grid>
  )
}

SubmissionContent.propTypes = {}

export default SubmissionContent
