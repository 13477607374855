import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
  Typography,
} from '@material-ui/core';

import { candidateSubmission, candidateWritingSample } from '../../constants/endpoint';
import classes from './index.module.css';

function FormSection(props) {
  const { 
    open,
  } = props;
  // Fields
  const [firstName, changeFirstName] = useState('');
  const [lastName, changeLastName] = useState('');
  const [emailAddress, changeEmailAddress] = useState('');
  const [phoneNumber, changePhoneNumber] = useState('');
  const [writingSampleType, changeWritingSampleType] = useState('');
  const [writingSampleText, changeWritingSampleText] = useState('');
  // Validation
  const [firstNameVal, validateFirstName] = useState(false);
  const [lastNameVal, validateLastName] = useState(false);
  const [emailAddressVal, validateEmailAddress] = useState(false);
  const [phoneNumberVal, validatePhoneNumber] = useState(false);
  const [writingSampleTypeVal, validateWritingSampleType] = useState(false);
  const [writingSampleTextVal, validateWritingSampleText] = useState(false);

  const fields = [
    {
      name: 'First Name',
      value: firstName,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateFirstName(true),
        },
      ],
    },
    {
      name: 'Last Name',
      value: lastName,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateLastName(true),
        },
      ],
    },
    {
      name: 'Email Address',
      value: emailAddress,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateEmailAddress(true),
        },
      ],
    },
    {
      name: 'Phone Number',
      value: phoneNumber,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validatePhoneNumber(true),
        },
      ],
    },
    // {
    //   name: 'Writing Sample Type',
    //   value: writingSampleType,
    //   rules: [
    //     {
    //       validate: (value) => value.trim().length <= 0,
    //       error: () => validateWritingSampleType(true),
    //     },
    //   ],
    // },
    {
      name: 'Writing Sample Text',
      value: writingSampleText,
      rules: [
        {
          validate: (value) => value.trim().length <= 0,
          error: () => validateWritingSampleText(true),
        },
      ],
    },
  ];


  const clearForm = () => {
    changeFirstName('');
    changeLastName('');
    changeEmailAddress('');
    changePhoneNumber('');
    changeWritingSampleType('');
    changeWritingSampleText('');
  }

  const handleCustomerSubmit = () => {
    let validation = true;
    fields.forEach((field) => {
      // console.log(`field: ${field.name}`);
      // console.log(`value: ${field.value}`);
      field.rules.forEach((rule) => {
        const valid = rule.validate(field.value);
        // console.log(`rule: ${valid}`)
        if (valid) {
          rule.error();
          validation = false;
        }
      })
    });

    if (validation) {

      // console.log('submitted');
      // console.log(open);
      const writingSampleRequest = {
        method: 'POST',
        data: {
          writingSample: writingSampleText,
        }
      };
      
      candidateWritingSample(writingSampleRequest)
        .then((res) => {
          const result = res;
          if (result) {
            const submissionRequest = {
              method: 'POST',
              data: {
                firstName,
                lastName,
                email: emailAddress,
                phone: phoneNumber,
                candidateWritingSampleId: result.id,
              },
            };
            candidateSubmission(submissionRequest);
          }
        })
      
      clearForm();
      open(true);
    }
  }

  const handleFieldChange = (value, change, validation, changeValidation) => {
    if (validation) changeValidation(false);
    change(value);
  }
 
  return (
    <Grid
      className={classes.formContainer}
      container
    >
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          md={8}
        >
          <Typography
            className={classes.formSectionTitle}
            variant='h5'
          >
            Personal Information
          </Typography>
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          md={4}
        >
          <TextField
            fullWidth
            value={firstName}
            onChange={(e) => handleFieldChange(e.target.value, changeFirstName, firstNameVal, validateFirstName)}
            variant='outlined'
            label='First Name*'
            error={firstNameVal}
            helperText={firstNameVal ? 'First name is required' : ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#fff',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000',
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px',
              }
            }}
            style={{
              backgroundColor: '#e9f5fe',
            }}
          />
        </Grid>
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          md={4}
        >
          <TextField
            fullWidth
            value={lastName}
            onChange={(e) => handleFieldChange(e.target.value, changeLastName, lastNameVal, validateLastName)}
            variant='outlined'
            label='Last Name*'
            error={lastNameVal}
            helperText={lastNameVal ? 'Last name is required': ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#fff',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px'
              }
            }}
            style={{
              backgroundColor: '#e9f5fe',
            }}
          />
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          md={4}
        >
          <TextField
            fullWidth
            value={emailAddress}
            onChange={(e) => handleFieldChange(e.target.value, changeEmailAddress, emailAddressVal, validateEmailAddress)}
            variant='outlined'
            label='Email Address*'
            error={emailAddressVal}
            helperText={emailAddressVal ? 'Email address is required' : ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#fff',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px'
              }
            }}
            style={{
              backgroundColor: '#e9f5fe',
            }}
          />
        </Grid>
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          md={4}
        >
          <TextField
            fullWidth
            value={phoneNumber}
            onChange={(e) => handleFieldChange(e.target.value, changePhoneNumber, phoneNumberVal, validatePhoneNumber)}
            variant='outlined'
            label='Phone Number*'
            error={phoneNumberVal}
            helperText={phoneNumberVal ? 'Phone number is required' : ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#fff',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px'
              }
            }}
            style={{
              backgroundColor: '#e9f5fe',
            }}
          />
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          md={8}
        >
          <Typography
            className={classes.formSectionTitle}
            variant='h5'
          >
            Provide Your Writing Sample
          </Typography>
          <Typography
            style={{
              marginTop: '20px',
            }}
          >
            A hiring company will be reviewing and assessing your communication style. Please provide a sample of 200 or more words. Common examples of a professional writing sample we suggest to leverage are: Cover Letter, Professional Email, Personal Bio, LinkedIn Bio, Memo, Professional Report, Commercial Writing Sample or any other writing piece used in a professional setting.
          </Typography>
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
      {/* <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={8}
        >
          <FormControl
            hiddenLabel
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel
              id="writing-type-label"
              shrink
              style={{
                color: '#000',
              }}
            >
              Writing Sample Type
            </InputLabel>
            <Select
              fullWidth
              id="hiringNeed"
              value={writingSampleType}
              onChange={(e) => handleFieldChange(e.target.value, changeWritingSampleType, writingSampleTypeVal, validateWritingSampleType)}
              labelId="writing-type-label"
              label="Writing Sample Type"
              placeholder='Please provide your sample of 200 or more words.'
              notched={true}
              error={writingSampleTypeVal}
              style={{
                backgroundColor: '#e9f5fe',
                borderRadius: '0px'
              }}
            >
              <MenuItem disabled value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="1-10">1-10</MenuItem>
              <MenuItem value="10-20">10-20</MenuItem>
              <MenuItem value="20+">20+</MenuItem>
            </Select>
            <FormHelperText
              error={writingSampleTypeVal}
              style={{
                display: writingSampleTypeVal ? 'block' : 'none'
              }}
            >
              Please select a writing sample type
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={2}
        />
      </Grid> */}
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.formInputContainer}
          item
          xs={12}
          md={8}
        >
          <TextField
            fullWidth
            multiline
            rows={6}
            value={writingSampleText}
            onChange={(e) => handleFieldChange(e.target.value, changeWritingSampleText, writingSampleTextVal, validateWritingSampleText)}
            variant='outlined'
            label='Writing Sample Text*'
            placeholder='Provide your sample of 200 or more words.'
            error={writingSampleTextVal}
            helperText={writingSampleTextVal ? 'Writing Sample Text is required' : ''}
            FormHelperTextProps={{
              style: {
                backgroundColor: '#fff',
                margin: 0,
                padding: '0 14px',
              }
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#000'
              }
            }}
            InputProps={{
              style: {
                borderRadius: '0px'
              }
            }}
            style={{
              backgroundColor: '#e9f5fe',
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
        />
      </Grid>
      <Grid
        className={classes.formContainerRow}
        container
      >
        <Grid
          item
          xs={0}
          md={6}
        />
        <Grid
          className={classes.formButtonContainer}
          item
          xs={12}
          md={4}
        >
          <Button
            className={classes.outlinedSkyBlueFilledButton}
            onClick={() => handleCustomerSubmit()}
          >
            Submit
          </Button>
        </Grid>
        <Grid
          item
          xs={2}
        />
      </Grid>
    </Grid>
  )
}

FormSection.propTypes = {}

export default FormSection
