import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import classes from './index.module.css';

function RecommendationSection(props) {
  const {
    writingSample,
    receptiviti,
    id,
  } = props;

  return (
    <Box
      className={classes.section}
    >
      <Box
        className={classes.snapshotContainer}
      >
        <img
          className={classes.icon}
          src="/icons/message-bubble-icon.svg"
          alt=""
        />
        <Typography
          className={classes.title}
          component="span"
        >
          Communication Recommendation
        </Typography>
      </Box>
      <Box
        className={classes.detailsContainer}
      >
        <Typography
          className={classes.description}
        >
          { receptiviti ? receptiviti.communication_recommendation : null}
        </Typography>
      </Box>
    </Box>
  );
}

RecommendationSection.propTypes = {}

export default RecommendationSection
